import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class StudyTypesRepository extends BaseRepository {
    baseUrl = 'management/bituminous-mixtures/study-types';

    /**
     * Fetches the next code number
     *
     * @return {*}
     */
    fetchNextCodeNumber() {
        return this.httpClient.get(`${this.baseUrl}/next-code-number`);
    }

    /**
     * Fetches all study types by lot intervention
     *
     * @param lotInterventionId
     * @return {*}
     */
    byLotIntervention(lotInterventionId) {
        return this.httpClient.get(`${this.baseUrl}/${lotInterventionId}/all`);
    }

    /**
     * Fetches all properties from a study type
     *
     * @param studyTypeId
     * @return {*}
     */
    properties(studyTypeId) {
        return this.httpClient.get(`${this.baseUrl}/${studyTypeId}/properties`);
    }
}
