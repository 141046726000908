import { inject }               from 'aurelia-framework';
import { PLATFORM }             from 'aurelia-pal';
import { BaseFormViewModel }    from 'base-form-view-model';
import { FormSchema }           from 'modules/management/bituminous-mixtures/study-types/form-schema';
import { StudyTypesRepository } from 'modules/management/bituminous-mixtures/study-types/services/repository';
import { AppContainer }         from 'resources/services/app-container';

@inject(AppContainer, StudyTypesRepository, FormSchema)
export class ViewStudyType extends BaseFormViewModel {
    formId = 'management.bituminous-mixtures.study-types.view-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/button-form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.bituminous-mixtures.study-types.manage',
            'management.bituminous-mixtures.study-types.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);
        this.schema       = this.formSchema.schema(this, true);
        this.globalSchema = this.formSchema.globalSchema(this, true);
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData({ id }) {
        const response = await this.repository.find(id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
